<template>
  <div v-if="checkPermission('list', 'action', 'url-trackings')">
    <topic-component :topic="$t('short_link.topic')"/>
    <b-card>
      <b-row>
        <b-col lg="3" md="4">
          <b-form-group label-cols="12" :label="$t('common.dateTime')" label-for="input-sm">
            <date-time-range-component v-model="search.date_time"/>
          </b-form-group>
        </b-col>
        <b-col lg="6" md="8">
          <b-form-group label-cols="12" :label="$t('short_link.url_name')" label-for="input-sm">
            <b-form-input v-model="search.title"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="3" md="4">
          <select-filter-component :label-select="$t('short_link.url_tracking_type')" v-model="search.url_link_type"
                                   :options="url_link_type"/>
        </b-col>
        <b-col lg="6" md="8">
          <b-form-group label-cols="12" :label="$t('short_link.url_link')" label-for="input-sm">
            <b-form-input v-model="search.url_link"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <div class="flex-center mb-md-3 mb-sm-3" style="margin-top: 35px;">
            <button type="button" class="btn waves-effect waves-light btn-outline mr-2" @click="resetFilter">
              {{ $t('common.resetSearch') }}
            </button>
            <button type="button" class="btn waves-effect waves-light btn-gradient" @click="getSearchList">
              {{ $t('common.Search') }}
              <b-spinner small v-show="is_loading_search"/>
            </button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div>
            <span>{{ $t('short_link.list.remark') }} <span class="text-danger"> {{ $t('short_link.list.delete_url')
            }}</span></span>
          </div>
        </b-col>
        <b-col align="end">
          <div v-if="!is_loading">
            <span v-if="!has_current_package" class="text-danger">
              {{ $t('short_link.order_package') }}
            </span>
            <span v-else-if="!url_tracking_limit" class="text-danger">
              {{ $t('short_link.url_credit_out') }}
            </span>
          </div>
        </b-col>
      </b-row>
      <hr>
      <b-row align-v="center" class="pb-3">
        <b-col>
          <div class="d-flex align-items-center">
            <span class="mr-3">{{ $t('common.perPage') }}</span>
            <div>
              <b-form-select
                  v-model="search.$limit"
                  :options="pageOptions"
                  size="sm"
                  @change="getList"
              />
            </div>
            <div class="ml-3">
              <total-list-component :total="totalAll"/>
            </div>
          </div>
        </b-col>
        <b-col align="end" class="mb-2">
          <b-button v-if="checkPermission('export', 'action', 'url-trackings')" variant="primary" class="mr-2"
                    :disabled="items.length == 0" @click="exportUrlTracking">
            <i class="fas fa-file-excel mr-1"/>
            {{ $t("button.export") }}
            <b-spinner small v-show="is_loading_export"/>
          </b-button>
          <b-button v-if="!has_current_package" variant="outline" @click="goToSubscriptionsPage">
            <div class="flex-center">
              <div style="width: 18px; height: 18px;" class="mr-1 svg-background"
                   :style="`${iconMaskURL('shopping-cart')}`">
              </div>
              {{ $t("short_link.buy_package") }}
            </div>
          </b-button>
          <b-button
              v-else
              :disabled="!url_tracking_limit || is_loading || !checkPermission('create', 'action', 'url-trackings')"
              @click="checkPermission('create', 'action', 'url-trackings') && createShortUrl()"
              variant="outline"
          >
            <i class="fas fa-plus-circle"/>
            {{ $t("short_link.add_short_url") }}
          </b-button>
        </b-col>
      </b-row>
      <b-table responsive class="mb-0" :items="items" :fields="field" :busy="isBusy" :empty-text="$t('empty')"
               show-empty>
        <template #head()="{ label, field: { key, sortable } }">
          {{ $t(label) }}
        </template>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong> {{ $t('loading') }}</strong>
          </div>
        </template>
        <template #empty="scope">
          <empty-table/>
        </template>
        <template #cell(createdAt)="data">
          {{ changeDateFormat(data.item.createdAt) }}
        </template>
        <template #cell(title)="data">
          {{ data.item.title }}
        </template>
        <template #cell(main_link)="data">
          <div style="max-width: 220px;">
            <a :href="data.item.main_link" target="_blank" class="text-info cutting-link">{{ data.item.main_link }}</a>
          </div>
        </template>
        <template #cell(short_link)="data">
          <div v-if="data.item.short_link" class="d-flex">
            <span>
              {{ data.item.short_link }}
            </span>
            <div :id="'11_'" class="ml-3 copy-icon" @click="copyText(data.item.short_link)">
              <i class="fas fa-copy"/>
            </div>
          </div>
        </template>
        <template #cell(total_click)="data">
          {{ changeClickedString(data.item.total_click) }}
        </template>
        <template #cell(total_ios)="data">
          {{ changeClickedString(data.item.total_ios) }}
        </template>
        <template #cell(total_android)="data">
          {{ changeClickedString(data.item.total_android) }}
        </template>
        <template #cell(total_other)="data">
          {{ changeClickedString(data.item.total_other) }}
        </template>
        <template #cell(active)="data">
          <div>
            <div
                v-if="data.item.total_click <= 0 && !!checkPermission('delete', 'action', 'url-trackings')"
                class="cursor-pointer"
                @click="deleteUrlTracking(data.item._id)"
            >
              <img
                  :src="require('@/assets/images/icon/delete.svg')"
                  alt="delete"
                  width="22"
              />
            </div>
            <div
                v-else-if="data.item.total_click > 0 && checkPermission('view', 'action', 'url-trackings')"
                class="cursor-pointer"
                @click="viewDetailsURL(data.item)"
            >
              <img
                  v-if="checkPermission('view', 'action', 'url-trackings')"
                  :src="require('@/assets/images/icon/eye-open.svg')"
                  alt="eye-open"
                  width="22"
              />
            </div>
          </div>
        </template>
      </b-table>
      <b-row>
        <div class="ml-auto">
          <b-pagination
              v-model="search.$skip"
              :total-rows="totalRows"
              :per-page="search.$limit"
              align="fill"
              class="my-0"
              size="sm"
              @input="getList"
          />
        </div>
      </b-row>
    </b-card>
    <create-short-url @success="createSuccess"/>
    <div>
      <b-modal id="modal-export" ok-variant="info" hide-footer no-close-on-backdrop>
        <template #modal-header="{ close }">
          <h5></h5>
          <i class="fas fa-times hover-icon action-button" @click="close"></i>
        </template>
        <div align="center" v-if="!exportSuccess">
          <b-spinner class="mt-5 mb-1" style="width: 4rem; height: 4rem" label="Large Spinner" variant="warning"
                     type="grow">
          </b-spinner>
          <h3 v-html="$t('processing')" class="mb-3"></h3>
        </div>
        <div v-if="exportSuccess">
          <div align="center" v-if="exportSuccess && step_success">
            <i class="fas fa-check-circle display-4 mb-3 d-block text-success mt-3 mb-2"/>
            <h3 v-html="$t('success')" class="mb-3"></h3>
            <div v-for="items in fileExport" style="width: 80%;">
              <a :href="items" class="ellipsis">
                <div class="ellipsis">{{ items }}</div>
              </a>
              <hr/>
            </div>
          </div>
          <div align="center" v-if="!exportSuccess && step_success">
            <i class="fas fa-times-circle display-4 mb-3 d-block text-danger mt-3 mb-2"/>
            <h3 v-html="$t('failed')" class="mb-3"></h3>
          </div>
        </div>
      </b-modal>
      <b-modal id="modal-response" ok-variant="info" hide-footer no-close-on-backdrop>
        <div class="text-center">
          <h2 class="text-green">{{ $t('short_link.add_short_url_s') }}</h2>
          <div class="font-size-18 mt-4">{{ $t('short_link.copy_link_and') }}</div>
          <div class="font-size-18 mt-4" style="color: #479B87">
            {{ $t('short_link.details.name_URL') }} : {{ response_created.title }}
          </div>
          <div class="flex-middle mt-4 mb-3">
            <div class="text-short-link" style="color: #479B87">
              {{ response_created.short_link }}
            </div>
            <div class="ml-2 copy-icon" @click="copyText(response_created.short_link)">
              <i class="fas fa-copy"/>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import TopicComponent from "@/components/common/topicComponent";
import masterData from "@/common/masterData.json";
import SelectFilterComponent from "@/components/common/selectFilterComponent";
import functionsCommon from "@/common/functions";
import functions from "@/common/functions";
import InputComponent from "@/components/common/inputComponent";
import shortLinkApi from "@/repository/shortLinkApi";
import CreateShortUrl from "@/views/shortLink/createShortUrl.vue";
import alert from "@/common/alert";
import authApi from "@/repository/authApi";
import DateTimeRangeComponent from "@/components/common/dateTimeRangeComponent";
import moment from "moment";
import EmptyTable from "@/components/common/emptyTable.vue";
import {store} from "@/store";
import DatePicker from 'vue2-datepicker';
import TotalListComponent from "@/components/common/totalList.vue";
// import 'vue2-datepicker/index.css';

export default {
  name: "Short-link",
  components: {
    TotalListComponent,
    CreateShortUrl,
    InputComponent,
    SelectFilterComponent,
    TopicComponent,
    DateTimeRangeComponent,
    EmptyTable,
    DatePicker
  },
  data: () => ({
    totalAll: 0,
    search: {
      url_link_type: null,
      url_link: '',
      name: '',
      $skip: 1,
      $limit: 10,
    },
    isBusy: false,
    field: [
      {key: "createdAt", label: "short_link.list.create_at"},
      {key: "title", label: "short_link.list.name_url"},
      {key: "main_link", label: "short_link.list.full_url"},
      {key: "short_link", label: "short_link.list.short_url"},
      {key: "total_click", label: "short_link.list.total_click", class: "text-center"},
      {key: "total_ios", label: "short_link.list.click_from_ios", class: "text-center"},
      {key: "total_android", label: "short_link.list.click_from_android", class: "text-center"},
      {key: "total_other", label: "short_link.list.click_other", class: "text-center"},
      {key: "active", label: "short_link.list.action", class: "text-center"},
    ],
    totalRows: 1,
    pageOptions: [10, 15, 50, 100],
    url_link_type: masterData.url_link_type,
    is_loading: true,
    is_loading_delete: false,
    is_loading_export: false,
    exportSuccess: false,
    step_success: false,
    url_tracking_limit: 1,
    has_current_package: true,
    items: [],
    response_created: {
      title: "",
      short_link: "",
    },
    lang: {
      formatLocale: {
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      },
      monthBeforeYear: false,
    },
    is_loading_search: false
  }),
  mounted() {
    try {
      this.getCustomerProfile()
    } catch (error) {
      console.warn(error)
    } finally {
      this.getList()
    }
  },
  methods: {
    iconMaskURL(name) {
      return functions.iconMaskURL(name)
    },
    goToSubscriptionsPage() {
      this.$router.push("/Subscriptions/Package");
    },
    changeClickedString(data) {
      let num = data ? Number(data) : 0
      return num ? num.toLocaleString() : "-"
    },
    changeDateFormat(date) {
      return date ? functions.changeDateFormat(date) : '-'
    },
    filterValue(arr, val) {
      return functions.filterMasterData(arr, val, this.$i18n.locale)
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
    createSuccess(res) {
      this.getList()
      this.getCustomerProfile()
      this.response_created = res;
      this.$bvModal.show('modal-response');
    },
    getSearchList() {
      this.is_loading_search = true
      this.getList()
    },
    getList() {
      this.isBusy = true;
      this.setDateTime();
      const clone = JSON.parse(JSON.stringify(this.search));
      shortLinkApi.getList(clone).then(resp => {
        this.items = resp.data
        this.totalRows = resp.total
        this.totalAll = resp.total
      }).catch(err => {
        throw err
      }).finally(() => {
        this.isBusy = false;
        this.is_loading_search = false
      })
    },
    resetFilter() {
      this.search = {
        url_link_type: null,
        url_link: '',
        title: '',
        date_time: null,
        $skip: 1,
        $limit: 10,
      }
      this.getList()
    },
    copyText(text) {
      navigator.clipboard.writeText(text);

      this.$bvToast.toast(`Copy Successful`, {
        title: 'Notice!',
        // toaster: 'b-toaster-bottom-right',
        variant: 'success',
        autoHideDelay: 2000
      })
    },
    deleteUrlTracking(id) {
      this.$swal.fire({
        icon: 'warning',
        title: this.$t('common.delete.topic'),
        text: this.$t('common.delete.detail'),
        showCancelButton: true,
        confirmButtonColor: '#EA5455',
        cancelButtonColor: '#7367F0',
        cancelButtonText: this.$t('button.cancel'),
        confirmButtonText: this.$t('common.delete_'),
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          shortLinkApi.deleteShortLink(id).then(resp => {
            if (!!resp) {
              alert.deleteSuccess(this)
            }
            this.getList()
            this.getCustomerProfile()
          }).catch(err => {
            alert.deleteFailed(this)
            throw err
          })
        } else {
          this.is_loading_delete = false
        }
      })
    },
    getCustomerProfile() {
      authApi.getCustomerProfile(true).then(resp => {
        this.url_tracking_limit = resp.url_tracking;
        this.has_current_package = resp.current_plan_id && (resp.is_using_trial !== true);
        if (resp?.codeSYS === '001') {
          // const total = resp?.sms_unit ? resp?.sms_unit : 0
          // store.dispatch('profile/setCredit', total)
          store.dispatch('profile/setProfileImg', resp?.image)
          // const url_tracking = resp?.url_tracking ? resp?.url_tracking : 0
          // store.dispatch('profile/setURLTracking', url_tracking)
          store.dispatch('profile/setName', resp?.first_name + " " + resp?.last_name)
        }
      }).catch(err => {
        throw err
      }).finally(() => {
        this.is_loading = false
      })
    },
    createShortUrl() {
      this.$bvModal.show('create-short-url')
    },
    setDateTime() {
      if (this.search.date_time) {
        const date_from = moment(this.search.date_time[0]).format('HH:mm:ss')
        const date_to = moment(this.search.date_time[1]).format('HH:mm:ss')
        if (date_from === '00:00:00' && date_to === '00:00:00') {
          this.search = {
            ...this.search,
            date_time: [
              new Date(moment(this.search.date_time[0]).format('YYYY-MM-DD') + 'T00:00:00'),
              new Date(moment(this.search.date_time[1]).format('YYYY-MM-DD') + 'T23:59:59')
            ]
          }
        }
      }
    },
    exportUrlTracking() {
      if (this.search.data_time) {
        this.setDateTime();
      }
      const text_search = this.search
      const clone = JSON.parse(JSON.stringify(text_search));
      this.is_loading_export = true
      if (this.is_loading_export) {
        this.$bvModal.show('modal-export')
        shortLinkApi.exportLink(clone).then(response => {
          if (response?.codeSYS === '001') {
            this.fileExport = response?.url_files
            this.exportSuccess = true
            this.step_success = true
          } else {
            this.exportSuccess = false
            this.step_success = false
          }
        }).catch(err => {
          this.$bvModal.hide('modal-export')
          alert.findFailed(this, err.response.data.message)
        }).finally(() => {
          this.is_loading_export = false
        })
      }

    },
    viewDetailsURL(item) {
      this.$router.push(`/Short-Link/Detail?ref_id=${item.ref_id}`)
    },
  },
  watch: {}
};
</script>
<style scoped>
.action-button {
  cursor: pointer;
}

.button-lg {
  margin-top: 10px;
  height: 42px !important;
}

.copy-icon:hover {
  color: var(--primary-color);
  cursor: pointer;
}

.copy-icon-success {
  position: absolute;
  padding: 1rem;
  background: #0c0d10;
  color: #FFFFFF;
}

.cutting-link {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-short-link {
  font-size: 18px;
  border-radius: 20px;
  border: 1px solid #E9E9E9;
  background: #F6F6F6;
  padding: 6px 24px;
  margin-right: 12px;
}
</style>
