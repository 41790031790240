<template>
  <div role="group">
    <div class="form-group mb-0">
      <b-row>
        <b-col class="col-auto" style="padding-right: 5px !important">
          <div v-if="info&&labelSelect" style="display: flex; gap: 8px; cursor: pointer">
            <label class="label-select text-secondary">{{ labelSelect }}</label>
            <slot></slot>
          </div>
          <div v-else>
            <label class="label-select text-secondary" :class="{'landing-text':landingPage}"> {{ labelSelect }}</label>
          </div>
        </b-col>
        <b-col
            v-if="required"
            align="left"
            class="col-2"
            style="padding-left: 5px !important"
        >
          <strong v-if="requireLabel || required" style="color: red">*</strong>
        </b-col>
        <b-col v-if="textRightRouter && pathRightRouter" class="col-auto ml-auto mb-2" align="right"
               style="padding-left: 5px !important">
          <span @click="checkBeforeRoute" class="text-primary font-weight-600 text-router">
            {{ $t(textRightRouter) }}
          </span>
        </b-col>
      </b-row>
      <b-form-select
          :value="value"
          :class="`input-select ${error} ${classInput}`"
          :style="`${componentStyle}`"
          :options="options"
          :v-model="value"
          @input="updateValue"
          @change="changeValue"
          :text-field="field"
          :state="state"
          :size="size"
          :disabled="disabledType"
      >
        <template #first>
          <b-form-select-option disabled :value="null">
            {{ placeholder ? "placeholder" : $t("common.pleaseSelectOption") }}
          </b-form-select-option>
        </template>
      </b-form-select>
    </div>
    <div>
      <label style="color: #fc6c6b; margin-bottom: 0px">{{
        requireLabel
        }}</label>
    </div>
  </div>
</template>

<script>
import subscriptionApi from "@/repository/subscriptionApi";

export default {
  name: "selectComponent",
  props: {
    selectId: String,
    placeholder: String,
    landingPage: {
      type: Boolean,
      default: false
    },
    info: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      required: false,
      default: null
    },
    state: {
      type: [String, Boolean],
      required: false,
      default: null
    },
    requireLabel: {
      type: String,
      request: false
    },
    error: {
      type: String,
      request: false
    },
    componentStyle: String,
    labelSelect: String,
    textField: {
      type: String,
      request: false
    },
    options: Array,
    required: {
      type: Boolean,
      request: false,
      default: false
    },
    radius: String,
    classInput: String,
    disabledType: Boolean,
    size: {
      type: String,
      request: false,
      default: ''
    },
    textRightRouter: {
      type: String,
      request: false,
      default: ''
    },
    pathRightRouter: {
      type: String,
      request: false,
      default: ''
    },
  },
  computed: {
    field() {
      return this.textField ? this.textField : (this.$i18n.locale ? this.$i18n.locale : 'en')
    },
    BrandSetting() {
      return JSON.parse(localStorage.getItem('brand_setting'))
    },
    userInfo () {
      return  this.$store.getters["profile/getProfile"]
    }
  },
  methods: {
    checkBeforeRoute() {
      if (this.pathRightRouter === '/Setting/SenderID/Create-SenderName') {
        if (this.userInfo?.identity_verify_status === 'verified' || this.userInfo?.is_using_trial || this.userInfo?.is_using_default) {
          subscriptionApi.getCurrentPackageDetail().then((res) => {
            if (res?.type === 'trial' || _.isNil(res?._id) || _.isNil(res?.expire_at)) {
              this.$router.push('/Setting/SenderID')
            } else {
              this.$router.push(this.pathRightRouter)
            }
          }).catch(() => {
            this.$router.push('/Setting/SenderID')
          })
        }else if (this.userInfo.identity_verify_status === 'waiting-approve') {
          this.$swal.fire({
            imageUrl:require('@/assets/images/icon/verify_waiting.svg'),
            title: this.$t('common.not_been_approve'),
            text: this.$t('common.please_check_your_auth_status_1') + (this.BrandSetting?.footer_text || 'SMS2PRO') + this.$t('common.please_check_your_auth_status_2'),
            showCancelButton: true,
            confirmButtonColor: '#19CBAC',
            cancelButtonColor: '#5B656E',
            cancelButtonText: this.$t('button.cancel'),
            confirmButtonText: this.$t('button.check'),
            allowOutsideClick: false,
            width:'40em',
            padding:'1.25rem'
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/Account').catch(() => {
              })
            }
          })
        }else if (this.userInfo.identity_verify_status === 'rejected') {
          this.$swal.fire({
            imageUrl:require('@/assets/images/icon/verify_failed.svg'),
            title: this.$t('common.not_been_success_auth'),
            text: this.$t('common.please_re_auth_enable_1') + (this.BrandSetting?.footer_text || 'SMS2PRO') + this.$t('common.please_re_auth_enable_2'),
            showCancelButton: true,
            confirmButtonColor: '#19CBAC',
            cancelButtonColor: '#5B656E',
            cancelButtonText: this.$t('button.cancel'),
            confirmButtonText: this.$t('button.verify'),
            allowOutsideClick: false,
            width:'40em',
            padding:'1.25rem'
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/Account').catch(() => {
              })
            }
          })
        } else {
          this.$swal.fire({
            imageUrl:require('@/assets/images/icon/verify.svg'),
            title: this.$t('common.has_not_been_auth'),
            text: this.$t('common.please_verify_your_credential'),
            showCancelButton: true,
            confirmButtonColor: '#19CBAC',
            cancelButtonColor: '#5B656E',
            cancelButtonText: this.$t('button.cancel'),
            confirmButtonText: this.$t('button.verify'),
            allowOutsideClick: false,
            width:'40em',
            padding:'1.25rem'
          }).then((result) => {
            if (result.isConfirmed) {
              this.$bvModal.show('modal-verify-account')
            }
          })
        }

      } else {
        this.$router.push(this.pathRightRouter)
      }
    },
    updateValue(event) {
      this.$emit('input', event)
    },
    changeValue(event) {
      this.$emit('change', event)
    }
  },
  data() {
    return {
      selectedValue: null,
    }
  },

}
</script>

<style scoped>
.landing-text {
  color: #525252 !important;
}

.input-select {
  /*// background: #FFFFFF;*/
  border: 1px solid #ced4da !important;
  box-sizing: border-box;
  border-radius: 8px;
  background: #fff url("../../assets/images/icon/arrow-down.svg") right 0.75rem center/15px 7px no-repeat;
  background-size: 20px 20px;
  height: calc(1.5em + 0.75rem + 2px);
  line-height: normal;
}

.custom-select {
  font-family: "Prompt";
  font-size: 0.875rem;
}

.label-select {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  padding-bottom: 0px;
}

.error {
  border-radius: 5px;
}

.text-router {
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}
</style>
